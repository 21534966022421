/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const BalooTamma2_400Regular = require('./BalooTamma2_400Regular.ttf');
export const BalooTamma2_500Medium = require('./BalooTamma2_500Medium.ttf');
export const BalooTamma2_600SemiBold = require('./BalooTamma2_600SemiBold.ttf');
export const BalooTamma2_700Bold = require('./BalooTamma2_700Bold.ttf');
export const BalooTamma2_800ExtraBold = require('./BalooTamma2_800ExtraBold.ttf');
